<gol-section-header [nav]="true">My Profile</gol-section-header>
<nav class="navbar navbar-light bg-light navbar-expand-md">
    <div class="container">
        <div class="navbar-header">
            <button type="button" class="navbar-toggler collapsed" data-bs-toggle="collapse"
                data-bs-target="#preferencesNavbar" aria-expanded="false" aria-controls="navbar">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
        </div>
        <div id="preferencesNavbar" class="collapse navbar-collapse">
            <ul class="nav navbar-nav">
                <li class="nav-item" routerLinkActive="current" data-bs-toggle="collapse"
                    data-bs-target="#preferencesNavbar.in" routerLink="/dashboard/preferences/contact"><a
                        routerLink="/dashboard/preferences/contact">Contact Information</a></li>
                <li class="nav-item" routerLinkActive="current" data-bs-toggle="collapse"
                    data-bs-target="#preferencesNavbar.in" routerLink="/dashboard/preferences/name"><a
                        routerLink="/dashboard/preferences/name">Profile Name</a></li>
                <li class="nav-item" routerLinkActive="current" data-bs-toggle="collapse"
                    data-bs-target="#preferencesNavbar.in" routerLink="/dashboard/preferences/password"><a
                        routerLink="/dashboard/preferences/password">Password</a></li>
                <li class="nav-item" routerLinkActive="current" data-bs-toggle="collapse"
                    data-bs-target="#preferencesNavbar.in" routerLink="/dashboard/preferences/alerts"><a
                        routerLink="/dashboard/preferences/alerts">Alerts</a></li>
                <li class="nav-item" routerLinkActive="current" data-bs-toggle="collapse"
                    data-bs-target="#preferencesNavbar.in" routerLink="/dashboard/preferences/security"><a
                        routerLink="/dashboard/preferences/security">Security Questions</a></li>
            </ul>
        </div>
    </div>
</nav>
<div class="profileStage">
    <div class="row">
        <div class="col-sm-2"></div>
        <div class="col-sm-8">
            <router-outlet></router-outlet>
        </div>
        <div class="col-sm-2"></div>
    </div>
</div>