<gol-section-header>My Dashboard</gol-section-header>
<gol-loading-feedback *ngIf="loadingAccounts || loadingApplications"></gol-loading-feedback>
<div class="dashboard" [hidden]="loadingAccounts || loadingApplications">    
    <gol-application-status-summary
        [style.order]="applicationOrder"        
        (hasOpenApplication)="returnHasOpenApplication($event)"
        (loadingApplications)="returnLoadingApplications($event)"></gol-application-status-summary>
    <gol-account-summary 
        [style.order]="2"
        (hasAccount)="returnHasAccount($event)" 
        (loadingAccounts)="returnloadingAccounts($event)">
    </gol-account-summary>
</div>