<div class="container age main_container raised-card">
    <div class="group">
        <div class="confirmation-status-icon">
            <i class="fa" [class.fa-check-square]="status.title === 'Submitted'"
                [class.fa-times-circle]="status.title !== 'Submitted'"></i>
        </div>
        <div class="confirmation-status-message">
            <h1>{{status.title}}</h1>
            <p>{{status.message}}</p>
            <div *ngIf="showContinue" class="options">
                <div class="option" data-bs-toggle="modal" (click)="openPrintDialog()">
                    <i class="fa fa-print"></i>
                    <span>Print and mail your request</span>
                </div>
                <div class="option" (click)="createNew()">
                    <i class="fa fa-file-text-o"></i>
                    <span>Start a new request</span>
                </div>
            </div>
        </div>
    </div>
    <p-dialog [(visible)]="showPrintDialog" modal="true" (onAfterHide)="closePrintDialog()">
        <ng-template pTemplate="header">
            Printing Instructions
        </ng-template>
        <div>
            <ol>
                <li>Click <i class="fa fa-print" aria-hidden="true" (click)="printApp()"></i> to print your filled in
                    authorized party request</li>
                <li>Review and sign the document in the appropriate locations</li>
                <li>Fax, email or mail your authorized party request to:
                    <ul>
                        <li class="sendOption">
                            <span>Fax:</span>
                            <div>(866) 349-3139</div>
                        </li>
                        <li class="sendOption">
                            <span>Email:</span>
                            <div>QuickLink@RaboAg.com</div>
                        </li>
                        <li class="sendOption">
                            <span>Mail:</span>
                            <div>
                                Rabo AgriFinance<br />
                                1402 Technology Parkway<br />
                                Cedar Falls, IA 50613
                            </div>
                        </li>
                    </ul>
                </li>
            </ol>
        </div>
        <div>
            <button class="btn btn-default bottom" (click)="closePrintDialog()">Cancel</button>
            <button class="btn btn-primary bottom" (click)="printApp()">Print Request <i
                    class="fa fa-print"></i></button>
        </div>
    </p-dialog>
    <div style="clear:both"></div>
</div>