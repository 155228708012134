import { Component } from '@angular/core';

@Component({
    selector: 'gol-dashboard-home',
    templateUrl: 'dashboard-home.component.html',
    styles: [`
        .dashboard {
            padding-bottom: 15px;
            display: flex;
            flex-direction: column;
        }
    `]
})
export class DashboardHomeComponent {

    loadingAccounts = true;
    loadingApplications = true;
    hasAccount: boolean;
    hasOpenApplication: boolean;

    public get applicationOrder(): number {        
        if(!this.hasAccount || (this.hasAccount && this.hasOpenApplication)){
            return 1;
        }

        if (this.hasAccount && !this.hasOpenApplication) {
            return 3;
        }
    }

    public returnHasAccount(event: boolean) {
        this.hasAccount = event;
    }

    public returnloadingAccounts(event: boolean) {
        this.loadingAccounts = event;
    }

    public returnHasOpenApplication(event: boolean) {
        this.hasOpenApplication = event;
    }

    public returnLoadingApplications(event: boolean) {
        this.loadingApplications = event;
    }
}