<div class="title-bar">Payoff Quote</div>
<gol-loading-feedback *ngIf="loading"></gol-loading-feedback>
<div *ngIf="error">
    <h2 class="error">There was an issue pulling the payoff details for this account.</h2>
</div>
<div *ngIf="!loading" class="raised-card">
    <div *ngIf="account?.HasPendingPayment">
        <h2>Existing Pending Payment Found</h2>
        <p class="bigger">There is an existing pending payment for this account. You cannot view payoff amount until
            the pending payment is processed or cancelled.
            Go to <a [routerLink]="['/dashboard/account/',loanNumber, 'payment-history']"
                routerLinkActive="active">Payment History</a> for more details.</p>
    </div>
    <div *ngIf="!account?.HasBalance">
        <h2>No Balance On Account</h2>
        <p class="bigger">None of the loans on this account have a balance to make a payment towards.
            Go to <a [routerLink]="['/dashboard/account/',loanNumber]" routerLinkActive="active">Account Details</a> for
            more information.</p>
    </div>
    <div *ngIf="!error && account?.HasBalance && !account?.HasPendingPayment" class="historyStage raised-card">
        <p>The payoff amounts below include {{payoffLength}} days of accrued interest to account for mailing. Use our
            online payment functionality to pay faster.</p>
        <p>Amounts identified below reflect interest charges that may accrue on the account in the future based on the
            current balance. Payments are applied on the date they are received.
            Payments or additional transactions prior to the date identified below may change the interest charges that
            will accrue on the account.</p>
        <p-table [value]="payments" [responsive]="true">

            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th>Dealer</th>
                    <th>Program</th>
                    <th>Payment Date</th>
                    <th>Payoff Amount</th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-row>
                <tr>
                    <td> {{row.DealerName }} </td>
                    <td> {{row.ProgramName}} </td>
                    <td> {{row.PaymentDate | date:'shortDate' }} </td>
                    <td> {{row.PayoffAmount | currency:'USD' }} </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="summary">
                <strong>Total Payoff Amount: {{TotalPayoffAmount() | currency:'USD'}}</strong>
            </ng-template>
        </p-table>
    </div>
</div>