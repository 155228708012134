<div class="title-bar">Statements and Documents</div>
<gol-loading-feedback *ngIf="loading"></gol-loading-feedback>
<div *ngIf="!loading" class="raised-card statements-stage">
    <h3>Monthly Statements</h3>
    <p *ngIf="statements.length == 0">
        No recent statements found for this account.
    </p>
    <div *ngIf="statements.length > 0">
        <p>Account statements for the last 18 months can be viewed below.</p>
        <p-accordion [multiple]="true">
            <p-accordionTab *ngFor="let statementYear of statementYears; let i = index" [selected]="i === 0">
                <ng-template pTemplate="header">
                    {{statementYear}}
                </ng-template>
                <span *ngFor="let statement of statements; let i = index">
                    <p *ngIf="statement.DocumentDate.substring(statement.DocumentDate.length - 4) == statementYear">
                        <a tabindex="0" class="link" (click)=downloadDocument(statement)><i
                                class="fa fa-download fa-1x"></i>{{statement.DocumentDate}}</a>
                    </p>
                </span>
            </p-accordionTab>
        </p-accordion>
    </div>
</div>
<div *ngIf="!loading" class="raised-card statements-stage">
    <h3>Interest Paid Statements</h3>
    <p *ngIf="interestPaidStatements.length == 0">
        No interest paid statements found for this account.
    </p>
    <div *ngIf="interestPaidStatements.length > 0">
        <p>Annual interest paid summary for your account.</p>
        <p *ngFor="let statement of interestPaidStatements">
            <a tabindex="0" class="link" (click)=downloadDocument(statement)><i
                    class="fa fa-download fa-1x"></i>{{statement.DocumentDate.substring(statement.DocumentDate.length - 4)}}</a>
        </p>
    </div>
</div>
<div>
    <p-toast></p-toast>
</div>