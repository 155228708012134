<div class="container stage main_container">
    <gol-side-nav selectedAlias="income" [validateSave]="validateSaveFunction.bind(this)"></gol-side-nav>
    <div class="row">
        <div class="col-sm-7">
            <h1>Financial Information - Balance Sheet</h1>
        </div>
        <div class="col-sm-5 change-app">
            <gol-change-application-type-component></gol-change-application-type-component>
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-sm-6">
            <p class="text-small">All fields are required unless otherwise noted.</p>
        </div>
    </div>
    <div class="row">
        <div  class="col-md-8">
            <form *ngIf="application" ngFormModel="form" (ngSubmit)="submit()" class="summary-section" novalidate>
                <div class="date-input-wrapper">
                    <mat-form-field>
                        <input matInput [formControl]="creationDate" (dateChange)="checkDateAge()"
                            [(ngModel)]="application.balanceSheet.creationDate" [matDatepicker]="picker"
                            placeholder="Date of Balance Sheet">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker name="creationDate" #picker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div *ngIf="form.submitted && !application.balanceSheet.creationDate && application.balanceSheet.totalEquity > 0"
                    class="error ng-invalid">Your balance sheet requires a date.</div>
                <div *ngIf="form.submitted && minDateError" class="error ng-invalid">The date must be more recent than
                    {{minDate | date}}.</div>
                <div *ngIf="form.submitted && maxDateError" class="error ng-invalid">You may not select a date in the
                    future.</div>
                <div class="category">
                    <h2>Total Assets</h2>
                    <ul class="financial-items">
                        <li class="group"
                            [ngClass]="{error: !currentAssets.valid && (currentAssets.dirty || form.submitted)}">
                            <label>Short Term:</label>
                            <div>
                                <gol-number-input name="currentAssets"
                                    [(ngModel)]="application.balanceSheet.shortTermAssets"
                                    (ngModelChange)="updateNetWorth();" [formControl]="currentAssets">
                                </gol-number-input>
                                <span *ngIf="form.submitted && financialValidation.hasNoValueError(currentAssets)"
                                    class="error ng-invalid">An amount is required</span>
                                <span
                                    *ngIf="form.submitted && financialValidation.hasOutOfRangePriceError(currentAssets)"
                                    class="error ng-invalid">Amount must be less than
                                    {{totalIncomeMax | currency:'USD':true:'1.2-2'}}</span>
                            </div>
                        </li>
                        <li class="group"
                            [ngClass]="{error: !intermediateAssets.valid && (intermediateAssets.dirty || form.submitted)}">
                            <label>Intermediate:</label>
                            <div>
                                <gol-number-input name="intermediateAssets"
                                    [(ngModel)]="application.balanceSheet.intermediateAssets"
                                    (ngModelChange)="updateNetWorth();" [formControl]="intermediateAssets">
                                </gol-number-input>
                                <span *ngIf="form.submitted && financialValidation.hasNoValueError(intermediateAssets)"
                                    class="error ng-invalid">An amount is required</span>
                                <span
                                    *ngIf="form.submitted && financialValidation.hasOutOfRangePriceError(intermediateAssets)"
                                    class="error ng-invalid">Amount must be less than
                                    {{totalIncomeMax | currency:'USD':true:'1.2-2'}}</span>
                            </div>
                        </li>
                        <li class="group"
                            [ngClass]="{error: !termAssets.valid && (termAssets.dirty || form.submitted)}">
                            <label>Long Term:</label>
                            <div>
                                <gol-number-input name="termAssets" [(ngModel)]="application.balanceSheet.termAssets"
                                    (ngModelChange)="updateNetWorth();" [formControl]="termAssets"></gol-number-input>
                                <span *ngIf="form.submitted && financialValidation.hasNoValueError(termAssets)"
                                    class="error ng-invalid">An amount is required</span>
                                <span *ngIf="form.submitted && financialValidation.hasOutOfRangePriceError(termAssets)"
                                    class="error ng-invalid">Amount must be less than
                                    {{totalIncomeMax | currency:'USD':true:'1.2-2'}}</span>
                            </div>
                        </li>
                    </ul>
                    <div class="total group">
                        <span class="green"
                            [class.error]="totalAssetsInvalid">{{application.balanceSheet.totalAssets | currency:'USD':'symbol':'1.2-2'}}</span>
                    </div>
                    <span *ngIf="totalAssetsInvalid" class="error">Amount must be less than
                        {{totalIncomeMax | currency:'USD':true:'1.2-2'}}</span>
                </div>

                <div class="category">
                    <h2>Total Liabilities</h2>
                    <ul class="financial-items">
                        <li class="group"
                            [ngClass]="{error: !currentLiabilities.valid && (currentLiabilities.dirty || form.submitted)}">
                            <label>Short Term:</label>
                            <div>
                                <gol-number-input name="currentLiabilities"
                                    [(ngModel)]="application.balanceSheet.shortTermLiabilities"
                                    (ngModelChange)="updateNetWorth();" [formControl]="currentLiabilities">
                                </gol-number-input>
                                <span *ngIf="form.submitted && financialValidation.hasNoValueError(currentLiabilities)"
                                    class="error ng-invalid">An amount is required</span>
                                <span
                                    *ngIf="form.submitted && financialValidation.hasOutOfRangePriceError(currentLiabilities)"
                                    class="error ng-invalid">Amount must be less than
                                    {{totalIncomeMax | currency:'USD':true:'1.2-2'}}</span>
                            </div>
                        </li>
                        <li class="group"
                            [ngClass]="{error: !intermediateLiabilities.valid && (intermediateLiabilities.dirty || form.submitted)}">
                            <label>Intermediate:</label>
                            <div>
                                <gol-number-input name="intermediateLiabilities"
                                    [(ngModel)]="application.balanceSheet.intermediateLiabilities"
                                    (ngModelChange)="updateNetWorth();" [formControl]="intermediateLiabilities">
                                </gol-number-input>
                                <span
                                    *ngIf="form.submitted && financialValidation.hasNoValueError(intermediateLiabilities)"
                                    class="error ng-invalid">An amount is required</span>
                                <span
                                    *ngIf="form.submitted && financialValidation.hasOutOfRangePriceError(intermediateLiabilities)"
                                    class="error ng-invalid">Amount must be less than
                                    {{totalIncomeMax | currency:'USD':true:'1.2-2'}}</span>
                            </div>
                        </li>
                        <li class="group"
                            [ngClass]="{error: !termLiabilities.valid && (termLiabilities.dirty || form.submitted)}">
                            <label>Long Term:</label>
                            <div>
                                <gol-number-input name="termLiabilities"
                                    [(ngModel)]="application.balanceSheet.termLiabilities"
                                    (ngModelChange)="updateNetWorth();" [formControl]="termLiabilities">
                                </gol-number-input>
                                <span *ngIf="form.submitted && financialValidation.hasNoValueError(termLiabilities)"
                                    class="error ng-invalid">An amount is required</span>
                                <span
                                    *ngIf="form.submitted && financialValidation.hasOutOfRangePriceError(termLiabilities)"
                                    class="error ng-invalid">Amount must be less than
                                    {{totalIncomeMax | currency:'USD':true:'1.2-2'}}</span>
                            </div>
                        </li>
                    </ul>
                    <div class="total group">
                        <span
                            class="red">{{application.balanceSheet.totalLiabilities | currency:'USD':'symbol':'1.2-2'}}</span>
                    </div>
                    <span *ngIf="totalLiabilitiesInvalid" class="error ng-invalid">Amount must be less than
                        {{totalIncomeMax | currency:'USD':true:'1.2-2'}}</span>
                </div>
                <div class="category read-only">
                    <h2>Net Worth</h2>
                    <ul class="financial-items net-worth">
                        <li class="group">
                            <label>Total Assets:</label>
                            <div><span>{{application.balanceSheet.totalAssets | currency:'USD':'symbol':'1.2-2'}}</span>
                            </div>
                        </li>
                        <li class="group">
                            <label>Total Liabilities:</label>
                            <div>
                                <span>{{application.balanceSheet.totalLiabilities | currency:'USD':'symbol':'1.2-2'}}</span>
                            </div>
                        </li>
                    </ul>
                    <div class="total group">
                        <span [class.green]="application.balanceSheet.totalEquity >= 0"
                            [class.red]="application.balanceSheet.totalEquity < 0">{{application.balanceSheet.totalEquity | currency:'USD':'symbol':'1.2-2'}}</span>
                    </div>
                </div>
                <div class="category read-only">
                    <h2>Total Income</h2>
                    <ul class="financial-items">
                        <li class="group">
                            <label>Gross Crop:</label>
                            <div><span>{{application.income.grossCropIncome | currency:'USD':'symbol':'1.2-2'}}</span>
                            </div>
                        </li>
                        <li class="group">
                            <label>Gross Livestock:</label>
                            <div>
                                <span>{{application.income.grossLivestockIncome  | currency:'USD':'symbol':'1.2-2'}}</span>
                            </div>
                        </li>
                        <li class="group">
                            <label>Other Farm:</label>
                            <div><span>{{application.income.otherFarmIncome | currency:'USD':'symbol':'1.2-2'}}</span>
                            </div>
                        </li>
                    </ul>
                    <div class="total group">
                        <span
                            class="green">{{application.income.totalFarmIncome | currency:'USD':'symbol':'1.2-2'}}</span>
                        <span *ngIf="totalFarmIncomeInvalid" class="error ng-invalid">Amount must be less than
                            {{totalIncomeMax | currency:'USD':true:'1.2-2'}}</span>
                    </div>
                </div>

                <div class="buttonContainer group" fxLayoutAlign="space-evenly">
                    <button *ngIf="showBack" class="btn btn-secondary back-btn" type="button"
                        (click)="back()"fxFlex.lt-md="40">Back</button>
                    <button type="submit" class="btn btn-primary" fxFlex.lt-md="40">Continue</button>
                </div>
            </form>
        </div>
        <gol-contact-component class="col-md-4"></gol-contact-component>
    </div>
    <div style="clear:both"></div>
</div>
<div>
    <if-notification defaultLife="12000"></if-notification>
</div>