<nav class="navbar navbar-light bg-light navbar-expand-md fixed-top">
  <div class="container" fxLayout>
    <div class="navbar-header">
      <button type="button" class="navbar-toggler collapsed" data-bs-toggle="collapse" data-bs-target="#navbar"
        aria-expanded="false" aria-controls="navbar">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <a class="navbar-brand" [routerLink]="getHomePage()">
        <img src="assets/images/Rabo_Logo.png" alt="Rabo AgriFinance logo"/>
      </a>
    </div>
    <div id="navbar" class="collapse navbar-collapse justify-content-end" fxHide.lt-md="true">
      <ul class="nav navbar-nav">
        <li class="nav-item" data-bs-toggle="collapse" data-bs-target="#navbar.in" *ngIf="userLoggedIn()"
          routerLinkActive="current" routerLink="/dashboard/overview"><a routerLink="/dashboard/overview">Home</a></li>
        <li class="nav-item" data-bs-toggle="collapse" data-bs-target="#navbar.in" *ngIf="showApplyHere()"
          routerLinkActive="current" routerLink="/apply"><a routerLink="/apply">Apply Here</a></li>
        <li class="nav-item" data-bs-toggle="collapse" data-bs-target="#navbar.in" routerLinkActive="current"
          routerLink="/contact"><a routerLink="/contact">Contact Us</a></li>
        <li class="nav-item" data-bs-toggle="collapse" data-bs-target="#navbar.in" *ngIf="!userLoggedIn()"
          routerLinkActive="current" routerLink="/registration"><a routerLink="/registration">Sign up</a></li>
        <li class="nav-item" *ngIf="!userLoggedIn()" class="active">
          <a data-bs-toggle="modal" data-bs-target="#loginModal" (click)="modalView = 'login'">Sign in</a>
        </li>
        <li *ngIf="userLoggedIn()" class="active dropdown nav-item">
          <a class="dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-haspopup="true"
            aria-expanded="false">{{currentUser.displayName}} <span class="caret"></span></a>
          <ul class="dropdown-menu">
            <li data-bs-toggle="collapse" data-bs-target="#navbar.in" routerLink="/dashboard"><a routerLink="/dashboard">My
                Dashboard</a></li>
            <li data-bs-toggle="collapse" data-bs-target="#navbar.in" routerLink="/dashboard/preferences"><a
                routerLink="/dashboard/preferences">My Profile</a></li>
            <li data-bs-toggle="collapse" data-bs-target="#navbar.in" (click)="logout()"><a (click)="logout()">Logout</a></li>
          </ul>
      </ul>
    </div>
  </div>
</nav>

<div class="modal fade" id="loginModal" tabindex="-1" role="dialog" aria-labelledby="loginModalLabel" #loginModal>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body position-relative">
        <button type="button" class="btn-close position-absolute top-0 end-0" data-bs-dismiss="modal" aria-label="Close"></button>
        <div *ngIf="modalView === 'login'">
            <gol-login (closeEvent)="close($event)"></gol-login>
            <p *ngIf="notice">{{notice}}</p>
            <br /><br />
            <div class="align-right">
                <p>
                    Or <a class="clickable" routerLink="/registration" data-dismiss="modal" aria-label="Close">
                        register new
                        user account
                    </a>
                </p>

                <p><a class="clickable" tabindex="0" (click)="notice='';modalView = 'forgot'">I forgot my password.</a></p>
            </div>
        </div>
        <gol-reset-password *ngIf="modalView === 'forgot'" (closeEvent)="closeResetPassword($event)">
        </gol-reset-password>
      </div>
    </div>
  </div>
</div>