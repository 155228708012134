<div class="title-bar">Transaction Report</div>
<div class="raised-card">
    <h3>Search Transactions</h3>
    <div class="row">
        <div class="col-md-3">
            <mat-form-field>
                <input matInput [(ngModel)]="query.MinimumDate" [matDatepicker]="minPicker" placeholder="Start Date"
                    [disabled]="loading" (dateChange)="dateChange()">
                <mat-datepicker-toggle matSuffix [for]="minPicker"></mat-datepicker-toggle>
                <mat-datepicker name="minimumDate" #minPicker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-md-3">
            <mat-form-field>
                <input matInput [(ngModel)]="query.MaximumDate" [matDatepicker]="maxPicker" placeholder="End Date"
                    [disabled]="loading" (dateChange)="dateChange()">
                <mat-datepicker-toggle matSuffix [for]="maxPicker"></mat-datepicker-toggle>
                <mat-datepicker name="maximumDate" #maxPicker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-md-3">
            <mat-form-field>
                <mat-select [(ngModel)]="query.Type" placeholder="Transaction Type" [disabled]="loading">
                    <mat-option [value]="">All</mat-option>
                    <mat-option *ngFor="let type of transactionReport.TransactionTypes" [value]="type">{{type}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-3">
            <mat-form-field>
                <mat-select [(ngModel)]="query.Program" placeholder="Program" [disabled]="loading">
                    <mat-option [value]="">All</mat-option>
                    <mat-option *ngFor="let program of transactionReport.Programs" [value]="program">{{program}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div *ngIf="datesInvalid" class="error">
        The end date must be after the start date
    </div>
    <button type="button" class="btn btn-primary" (click)="getTransactionReport()"
        [disabled]="loading || datesInvalid">Search</button>
    <button type="button" class="btn btn-default" (click)="clearQuery()" [disabled]="loading">Clear</button>
    <div class="bottom"></div>
</div>
<div class="raised-card">
    <gol-loading-feedback *ngIf="loading"></gol-loading-feedback>
    <div *ngIf="error" class="error">
        There was an error pulling the transaction report
    </div>
    <p-table *ngIf="!loading && !error" [value]="transactionReport.Transactions" [responsive]="true">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th [pSortableColumn]="'TransactionDate'">Date<p-sortIcon [field]="'TransactionDate'">
                    </p-sortIcon>
                </th>
                <th [pSortableColumn]="'Type'">Type<p-sortIcon [field]="'Type'">
                    </p-sortIcon>
                </th>
                <th [pSortableColumn]="'Program'">Program<p-sortIcon [field]="'Program'">
                    </p-sortIcon>
                </th>
                <th [pSortableColumn]="'Vendor'">Vendor<p-sortIcon [field]="'Vendor'">
                    </p-sortIcon>
                </th>
                <th [pSortableColumn]="'Amount'">Amount<p-sortIcon [field]="'Amount'">
                    </p-sortIcon>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-row>
            <tr [pSelectableRow]="row">
                <td> {{row.TransactionDate  | date:'MM/dd/yyyy' }} </td>
                <td> {{row.Type}} </td>
                <td> {{row.Program }} </td>
                <td> {{row.Vendor }} </td>
                <td> {{row.Amount | currency:'USD' }} </td>
            </tr>
        </ng-template>

    </p-table>
</div>