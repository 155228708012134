<div class="container stage main_container">
    <gol-side-nav selectedAlias="{{pageAlias}}" [validateSave]="validateSaveFunction.bind(this)"></gol-side-nav>
    <div class="row">
        <div class="col-sm-7">
            <h1>Line of Business Information</h1>
        </div>
        <div class="col-sm-5 change-app">
            <gol-change-application-type-component></gol-change-application-type-component>
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-sm-6">
            <p class="text-small">All fields are required unless otherwise noted.</p>
        </div>
    </div>
    <div class="row">
        <div  class="col-md-8">
            <div class="lineOfBusiness panel group">
                <form ngFormModel="form" (ngSubmit)="submit()" novalidate *ngIf="application">
                    <div class="control-group rc-wrapper">
                        <h3>What is your <em>primary</em> line of business?</h3>
                        <gol-radio-complete [options]="businesses" [value]="application.lineOfBusiness"
                            (changedValue)="ngOnChanges($event)"></gol-radio-complete>
                        <div *ngIf="!application.lineOfBusiness && form.submitted" class="error rc-error">You must pick
                            a line of business</div>
                    </div>
                    <gol-increased-risk-countries [(increasedRiskCountries)]="application.increasedRiskCountries" [submitted]="form.submitted"></gol-increased-risk-countries>
                    <div class="buttonContainer group">
                        <button *ngIf="showBack" class="btn btn-secondary back-btn" type="button"
                            (click)="previous()" fxFlex.lt-md="40">Back</button>
                        <button type="submit" class="btn btn-primary" fxFlex.lt-md="40">Continue</button>
                    </div>
                </form>
            </div>
        </div>
        <gol-contact-component class="col-md-4"></gol-contact-component>
    </div>
    <div style="clear:both"></div>
</div>
<div>
    <if-notification defaultLife="12000"></if-notification>
</div>