<div class="title-bar">Account Details</div>
<gol-loading-feedback *ngIf="loading"></gol-loading-feedback>
<div *ngIf="error" class="raised-card">
    <h2 class="error">There was an issue pulling this account's details.</h2>
    <button type="button" class="btn btn-primary bottom" [routerLink]="['/dashboard']"
        routerLinkActive="active">Cancel</button>
    <div class="clear"></div>
</div>
<div *ngIf="!loading && !error && account != null" class="raised-card">
    <div class="sc-wrapper" *ngFor="let sc of account.SubCommitments; let i=index">
        <gol-subcommitment-details [subcommitment]=sc [commitment]=account></gol-subcommitment-details>
    </div>
</div>