<div class="title-bar">Payment History</div>
<gol-loading-feedback *ngIf="loading"></gol-loading-feedback>
<div *ngIf="!loading && error" class="raised-card">
    <h2 class="error">There was an issue pulling the payment history for this account.</h2>
</div>
<div *ngIf="!loading && !error" class="raised-card">
    <div *ngIf="pendingPayments?.length > 0">
        <h1>Pending Payments</h1>
        <div class="raised-card group">
            <p-table [value]="pendingPayments" [responsive]="true">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th>Confirmation Number</th>
                        <th>Dealer</th>
                        <th>Program</th>
                        <th>Payment Date</th>
                        <th>Payment Amount</th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-row>
                    <tr>
                        <td> {{row.ConfirmationNumber }} </td>
                        <td> {{row.DealerName }} </td>
                        <td> {{row.ProgramName}} </td>
                        <td> {{row.PaymentDate | date:'shortDate' }} </td>
                        <td> {{row.PaymentAmount | currency:'USD' }} </td>
                    </tr>
                </ng-template>
            </p-table>
            <button class="btn btn-flat" type="button" (click)="cancelClick()">Cancel</button>
        </div>
    </div>
    <div>
        <h1>Payment History</h1>
        <div class="raised-card">
            <p-table [value]="completedPayments" [responsive]="true">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th>Dealer</th>
                        <th>Program</th>
                        <th>Payment Date</th>
                        <th>Payment Amount</th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-row>
                    <tr>
                        <td> {{row.DealerName }} </td>
                        <td> {{row.ProgramName}} </td>
                        <td> {{row.PaymentDate | date:'shortDate' }} </td>
                        <td> {{row.PaymentAmount | currency:'USD' }} </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
<p-dialog [responsive]="true" header="Cancel Payment" [(visible)]="cancelingPayment">
    Please confirm that you want to cancel all pending payments.<br /><br />
    <div class="dialogFooter">
        <button class="btn btn-primary" (click)="cancelPending()" [disabled]="submitting">Continue</button>
        <button class="btn btn-secondary" (click)="cancelCancel()" [disabled]="submitting">Cancel</button>
    </div>
</p-dialog>
<p-dialog [responsive]="true" header="Error" [(visible)]="cancelError">
    There was an issue cancelling the payments.<br /><br />
    <div class="dialogFooter">
        <button class="btn btn-secondary" (click)="closeCancelError()" [disabled]="submitting">Close</button>
    </div>
</p-dialog>
<p-dialog [responsive]="true" header="Cancel Confirmation" [(visible)]="cancelConfirmation"
    (onAfterHide)="closeCancelConfirmation()">
    Pending payments have been cancelled.<br /><br />
    <p>{{ConfirmationText()}}</p>
    <p *ngFor="let confirmationNumber of reversalIds; let i=index">{{confirmationNumber}}</p>
    <div class="dialogFooter">
        <button class="btn btn-secondary" (click)="closeCancelConfirmation()" [disabled]="submitting">Close</button>
    </div>
</p-dialog>